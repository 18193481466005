@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurial Grotesk';
  src: url('assets/fonts/NeurialGrotesk-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Neurial Grotesk", system-ui, sans-serif;
}

.mapboxgl-popup-content{
  padding:0 !important;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 40px;
  height: 10px;
  top: 0;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  background-color: #474c6e;
  padding: 0 3px 3px 0;
  cursor: ns-resize;
}

@layer base {
  html {
    font-family: Neurial Grotesk, system-ui, sans-serif;
    -webkit-tap-highlight-color: transparent;
  }
  body {
    color: #474c6e;
  }
  
}

@layer utilities {}

@layer components {}